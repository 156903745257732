import '../css/main.scss';
import '../css/index.scss';

import $ from 'jQuery';
import './common/nav';

function initSwiper() {
    new Swiper('.swiper-container', {
        // Optional parameters
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

$(() => {
    $('.scroll-animate').appear();
    $(document.body).on('appear', '.scroll-animate', function(e, els) {
       $(this).addClass('animate__animated');
    });

    initSwiper();

    $('#to-evaluation-btn').click(() => {
        location.href = 'evaluation.html';
    });
});
